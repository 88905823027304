.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // background-color: rgba(0,0,0,.8);
  background-color: white;
  z-index: 11;

  .css-m2td1u {
    margin: 50px;
  }
}

.centerContainer {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.subtext {
  margin-top: 30px;
  color: #aaa;
}
